/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: string; output: string; }
};

export type GqlCharacter = {
  __typename?: 'GQLCharacter';
  avatarId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  systemPrompt: Scalars['String']['output'];
  timeCreated: Scalars['DateTime']['output'];
  user?: Maybe<GqlUser>;
};

export type GqlCharacterCreator = {
  avatarId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  systemPrompt: Scalars['String']['input'];
};

export type GqlCharacterUpdater = {
  name?: InputMaybe<Scalars['String']['input']>;
  systemPrompt?: InputMaybe<Scalars['String']['input']>;
};

export type GqlConversation = {
  __typename?: 'GQLConversation';
  character: GqlCharacter;
  characterCursor?: Maybe<Scalars['String']['output']>;
  characterId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isNsfw: Scalars['Boolean']['output'];
  lastMessage?: Maybe<GqlMessage>;
  lastMessageId?: Maybe<Scalars['String']['output']>;
  messages: Array<GqlMessage>;
  timeCreated: Scalars['DateTime']['output'];
  timeEnded?: Maybe<Scalars['String']['output']>;
  user: GqlUser;
  userId: Scalars['String']['output'];
};

export type GqlConversationCreator = {
  characterId: Scalars['String']['input'];
};

export type GqlCreatedMedia = {
  __typename?: 'GQLCreatedMedia';
  get: GqlPresignedUrl;
  media: GqlMedia;
  put: GqlPresignedUrl;
};

export type GqlMedia = {
  __typename?: 'GQLMedia';
  contentType: Scalars['String']['output'];
  downloadStatus: MediaDownloadStatus;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  uploadStatus: MediaUploadStatus;
  userId: Scalars['String']['output'];
};

export type GqlMediaCreator = {
  contentType: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GqlMessage = {
  __typename?: 'GQLMessage';
  character?: Maybe<GqlCharacter>;
  characterId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  conversationId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isError: Scalars['Boolean']['output'];
  isFlagged: Scalars['Boolean']['output'];
  role: MessageRole;
  timeCreated: Scalars['DateTime']['output'];
  user?: Maybe<GqlUser>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type GqlMessageCreator = {
  characterId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  conversationId: Scalars['String']['input'];
};

export type GqlPresignedUrl = {
  __typename?: 'GQLPresignedUrl';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GqlSearchCharactersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type GqlUploadUrl = {
  __typename?: 'GQLUploadUrl';
  get: GqlPresignedUrl;
  put: GqlPresignedUrl;
};

export type GqlUser = {
  __typename?: 'GQLUser';
  apiKey: Scalars['String']['output'];
  avatarId?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  timeCreated: Scalars['DateTime']['output'];
};

export enum MediaDownloadStatus {
  downloaded = 'downloaded',
  started_download = 'started_download',
  waiting_for_upload = 'waiting_for_upload'
}

export enum MediaUploadStatus {
  failed = 'failed',
  uploaded = 'uploaded',
  uploading = 'uploading'
}

export enum MessageRole {
  assistant = 'assistant',
  start = 'start',
  user = 'user'
}

export type Mutation = {
  __typename?: 'Mutation';
  createCharacter: GqlCharacter;
  createConversation: GqlCharacter;
  createMedia: Array<GqlCreatedMedia>;
  createMessage: GqlConversation;
  setIsTyping: Scalars['Boolean']['output'];
  setMediaUploadStatus: GqlMedia;
  startConversation: GqlConversation;
  updateCharacter: GqlCharacter;
};


export type MutationCreateCharacterArgs = {
  creator: GqlCharacterCreator;
};


export type MutationCreateConversationArgs = {
  creator: GqlConversationCreator;
};


export type MutationCreateMediaArgs = {
  creators: Array<GqlMediaCreator>;
};


export type MutationCreateMessageArgs = {
  creator: GqlMessageCreator;
};


export type MutationSetIsTypingArgs = {
  conversationId: Scalars['String']['input'];
};


export type MutationSetMediaUploadStatusArgs = {
  id: Scalars['String']['input'];
  status: MediaUploadStatus;
};


export type MutationStartConversationArgs = {
  characterId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdateCharacterArgs = {
  id: Scalars['String']['input'];
  updater: GqlCharacterUpdater;
};

export type Query = {
  __typename?: 'Query';
  allMedia: Array<GqlMedia>;
  character: GqlCharacter;
  conversation: GqlConversation;
  isTyping: Array<Scalars['String']['output']>;
  me?: Maybe<GqlUser>;
  message: GqlMessage;
  messages: Array<GqlMessage>;
  presignedGetMediaUrl: GqlPresignedUrl;
  presignedPutAvatarUrl: GqlPresignedUrl;
  searchCharacters: Array<GqlCharacter>;
  uploadMediaUrl: GqlUploadUrl;
  user: GqlUser;
};


export type QueryCharacterArgs = {
  id: Scalars['String']['input'];
};


export type QueryConversationArgs = {
  id: Scalars['String']['input'];
};


export type QueryIsTypingArgs = {
  conversationId: Scalars['String']['input'];
};


export type QueryMessageArgs = {
  id: Scalars['String']['input'];
};


export type QueryMessagesArgs = {
  conversationId: Scalars['String']['input'];
};


export type QueryPresignedGetMediaUrlArgs = {
  key: Scalars['String']['input'];
};


export type QueryPresignedPutAvatarUrlArgs = {
  contentType: Scalars['String']['input'];
};


export type QuerySearchCharactersArgs = {
  input: GqlSearchCharactersInput;
};


export type QueryUploadMediaUrlArgs = {
  contentType: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type MeFragment = { __typename?: 'GQLUser', id: string };

export type MediaFragment = { __typename?: 'GQLMedia', id: string, contentType: string, name: string };

export type PresignedUrlFragment = { __typename?: 'GQLPresignedUrl', id: string, url: string };

export type UserFragment = { __typename?: 'GQLUser', id: string };

export type CreateMediaVariables = Exact<{
  creators: Array<GqlMediaCreator> | GqlMediaCreator;
}>;


export type CreateMedia = { __typename?: 'Mutation', createMedia: Array<{ __typename?: 'GQLCreatedMedia', media: { __typename?: 'GQLMedia', id: string, contentType: string, name: string }, put: { __typename?: 'GQLPresignedUrl', id: string, url: string }, get: { __typename?: 'GQLPresignedUrl', id: string, url: string } }> };

export type SetMediaUploadStatusVariables = Exact<{
  id: Scalars['String']['input'];
  status: MediaUploadStatus;
}>;


export type SetMediaUploadStatus = { __typename?: 'Mutation', setMediaUploadStatus: { __typename?: 'GQLMedia', id: string, contentType: string, name: string } };

export type FetchAllMediaVariables = Exact<{ [key: string]: never; }>;


export type FetchAllMedia = { __typename?: 'Query', allMedia: Array<{ __typename?: 'GQLMedia', id: string, contentType: string, name: string }> };

export type FetchMeVariables = Exact<{ [key: string]: never; }>;


export type FetchMe = { __typename?: 'Query', me?: { __typename?: 'GQLUser', id: string } | null };

export type FetchPresignedGetMediaUrlVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type FetchPresignedGetMediaUrl = { __typename?: 'Query', presignedGetMediaUrl: { __typename?: 'GQLPresignedUrl', id: string, url: string } };

export type FetchPresignedPutAvatarUrlVariables = Exact<{
  contentType: Scalars['String']['input'];
}>;


export type FetchPresignedPutAvatarUrl = { __typename?: 'Query', presignedPutAvatarUrl: { __typename?: 'GQLPresignedUrl', id: string, url: string } };

export type FetchUploadMediaUrlVariables = Exact<{
  contentType: Scalars['String']['input'];
}>;


export type FetchUploadMediaUrl = { __typename?: 'Query', uploadMediaUrl: { __typename?: 'GQLUploadUrl', get: { __typename?: 'GQLPresignedUrl', id: string, url: string }, put: { __typename?: 'GQLPresignedUrl', id: string, url: string } } };

export const UserFragment = gql`
    fragment UserFragment on GQLUser {
  id
}
    `;
export const MeFragment = gql`
    fragment MeFragment on GQLUser {
  ...UserFragment
}
    ${UserFragment}`;
export const MediaFragment = gql`
    fragment MediaFragment on GQLMedia {
  id
  contentType
  name
}
    `;
export const PresignedUrlFragment = gql`
    fragment PresignedUrlFragment on GQLPresignedUrl {
  id
  url
}
    `;
export const CreateMediaDocument = gql`
    mutation CreateMedia($creators: [GQLMediaCreator!]!) {
  createMedia(creators: $creators) {
    media {
      ...MediaFragment
    }
    put {
      ...PresignedUrlFragment
    }
    get {
      ...PresignedUrlFragment
    }
  }
}
    ${MediaFragment}
${PresignedUrlFragment}`;
export type CreateMediaMutationFn = Apollo.MutationFunction<CreateMedia, CreateMediaVariables>;

/**
 * __useCreateMedia__
 *
 * To run a mutation, you first call `useCreateMedia` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedia` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedia, { data, loading, error }] = useCreateMedia({
 *   variables: {
 *      creators: // value for 'creators'
 *   },
 * });
 */
export function useCreateMedia(baseOptions?: Apollo.MutationHookOptions<CreateMedia, CreateMediaVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMedia, CreateMediaVariables>(CreateMediaDocument, options);
      }
export type CreateMediaHookResult = ReturnType<typeof useCreateMedia>;
export type CreateMediaMutationResult = Apollo.MutationResult<CreateMedia>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<CreateMedia, CreateMediaVariables>;
export const SetMediaUploadStatusDocument = gql`
    mutation SetMediaUploadStatus($id: String!, $status: MediaUploadStatus!) {
  setMediaUploadStatus(id: $id, status: $status) {
    ...MediaFragment
  }
}
    ${MediaFragment}`;
export type SetMediaUploadStatusMutationFn = Apollo.MutationFunction<SetMediaUploadStatus, SetMediaUploadStatusVariables>;

/**
 * __useSetMediaUploadStatus__
 *
 * To run a mutation, you first call `useSetMediaUploadStatus` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMediaUploadStatus` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMediaUploadStatus, { data, loading, error }] = useSetMediaUploadStatus({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetMediaUploadStatus(baseOptions?: Apollo.MutationHookOptions<SetMediaUploadStatus, SetMediaUploadStatusVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMediaUploadStatus, SetMediaUploadStatusVariables>(SetMediaUploadStatusDocument, options);
      }
export type SetMediaUploadStatusHookResult = ReturnType<typeof useSetMediaUploadStatus>;
export type SetMediaUploadStatusMutationResult = Apollo.MutationResult<SetMediaUploadStatus>;
export type SetMediaUploadStatusMutationOptions = Apollo.BaseMutationOptions<SetMediaUploadStatus, SetMediaUploadStatusVariables>;
export const FetchAllMediaDocument = gql`
    query FetchAllMedia {
  allMedia {
    ...MediaFragment
  }
}
    ${MediaFragment}`;

/**
 * __useFetchAllMedia__
 *
 * To run a query within a React component, call `useFetchAllMedia` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllMedia` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllMedia({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllMedia(baseOptions?: Apollo.QueryHookOptions<FetchAllMedia, FetchAllMediaVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAllMedia, FetchAllMediaVariables>(FetchAllMediaDocument, options);
      }
export function useFetchAllMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllMedia, FetchAllMediaVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAllMedia, FetchAllMediaVariables>(FetchAllMediaDocument, options);
        }
export function useFetchAllMediaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchAllMedia, FetchAllMediaVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchAllMedia, FetchAllMediaVariables>(FetchAllMediaDocument, options);
        }
export type FetchAllMediaHookResult = ReturnType<typeof useFetchAllMedia>;
export type FetchAllMediaLazyQueryHookResult = ReturnType<typeof useFetchAllMediaLazyQuery>;
export type FetchAllMediaSuspenseQueryHookResult = ReturnType<typeof useFetchAllMediaSuspenseQuery>;
export type FetchAllMediaQueryResult = Apollo.QueryResult<FetchAllMedia, FetchAllMediaVariables>;
export const FetchMeDocument = gql`
    query FetchMe {
  me {
    ...MeFragment
  }
}
    ${MeFragment}`;

/**
 * __useFetchMe__
 *
 * To run a query within a React component, call `useFetchMe` and pass it any options that fit your needs.
 * When your component renders, `useFetchMe` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMe({
 *   variables: {
 *   },
 * });
 */
export function useFetchMe(baseOptions?: Apollo.QueryHookOptions<FetchMe, FetchMeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
      }
export function useFetchMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMe, FetchMeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
        }
export function useFetchMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchMe, FetchMeVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
        }
export type FetchMeHookResult = ReturnType<typeof useFetchMe>;
export type FetchMeLazyQueryHookResult = ReturnType<typeof useFetchMeLazyQuery>;
export type FetchMeSuspenseQueryHookResult = ReturnType<typeof useFetchMeSuspenseQuery>;
export type FetchMeQueryResult = Apollo.QueryResult<FetchMe, FetchMeVariables>;
export const FetchPresignedGetMediaUrlDocument = gql`
    query FetchPresignedGetMediaUrl($key: String!) {
  presignedGetMediaUrl(key: $key) {
    ...PresignedUrlFragment
  }
}
    ${PresignedUrlFragment}`;

/**
 * __useFetchPresignedGetMediaUrl__
 *
 * To run a query within a React component, call `useFetchPresignedGetMediaUrl` and pass it any options that fit your needs.
 * When your component renders, `useFetchPresignedGetMediaUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPresignedGetMediaUrl({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useFetchPresignedGetMediaUrl(baseOptions: Apollo.QueryHookOptions<FetchPresignedGetMediaUrl, FetchPresignedGetMediaUrlVariables> & ({ variables: FetchPresignedGetMediaUrlVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPresignedGetMediaUrl, FetchPresignedGetMediaUrlVariables>(FetchPresignedGetMediaUrlDocument, options);
      }
export function useFetchPresignedGetMediaUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPresignedGetMediaUrl, FetchPresignedGetMediaUrlVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPresignedGetMediaUrl, FetchPresignedGetMediaUrlVariables>(FetchPresignedGetMediaUrlDocument, options);
        }
export function useFetchPresignedGetMediaUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchPresignedGetMediaUrl, FetchPresignedGetMediaUrlVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchPresignedGetMediaUrl, FetchPresignedGetMediaUrlVariables>(FetchPresignedGetMediaUrlDocument, options);
        }
export type FetchPresignedGetMediaUrlHookResult = ReturnType<typeof useFetchPresignedGetMediaUrl>;
export type FetchPresignedGetMediaUrlLazyQueryHookResult = ReturnType<typeof useFetchPresignedGetMediaUrlLazyQuery>;
export type FetchPresignedGetMediaUrlSuspenseQueryHookResult = ReturnType<typeof useFetchPresignedGetMediaUrlSuspenseQuery>;
export type FetchPresignedGetMediaUrlQueryResult = Apollo.QueryResult<FetchPresignedGetMediaUrl, FetchPresignedGetMediaUrlVariables>;
export const FetchPresignedPutAvatarUrlDocument = gql`
    query FetchPresignedPutAvatarUrl($contentType: String!) {
  presignedPutAvatarUrl(contentType: $contentType) {
    ...PresignedUrlFragment
  }
}
    ${PresignedUrlFragment}`;

/**
 * __useFetchPresignedPutAvatarUrl__
 *
 * To run a query within a React component, call `useFetchPresignedPutAvatarUrl` and pass it any options that fit your needs.
 * When your component renders, `useFetchPresignedPutAvatarUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPresignedPutAvatarUrl({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useFetchPresignedPutAvatarUrl(baseOptions: Apollo.QueryHookOptions<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables> & ({ variables: FetchPresignedPutAvatarUrlVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>(FetchPresignedPutAvatarUrlDocument, options);
      }
export function useFetchPresignedPutAvatarUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>(FetchPresignedPutAvatarUrlDocument, options);
        }
export function useFetchPresignedPutAvatarUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>(FetchPresignedPutAvatarUrlDocument, options);
        }
export type FetchPresignedPutAvatarUrlHookResult = ReturnType<typeof useFetchPresignedPutAvatarUrl>;
export type FetchPresignedPutAvatarUrlLazyQueryHookResult = ReturnType<typeof useFetchPresignedPutAvatarUrlLazyQuery>;
export type FetchPresignedPutAvatarUrlSuspenseQueryHookResult = ReturnType<typeof useFetchPresignedPutAvatarUrlSuspenseQuery>;
export type FetchPresignedPutAvatarUrlQueryResult = Apollo.QueryResult<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>;
export const FetchUploadMediaUrlDocument = gql`
    query FetchUploadMediaUrl($contentType: String!) {
  uploadMediaUrl(contentType: $contentType) {
    get {
      ...PresignedUrlFragment
    }
    put {
      ...PresignedUrlFragment
    }
  }
}
    ${PresignedUrlFragment}`;

/**
 * __useFetchUploadMediaUrl__
 *
 * To run a query within a React component, call `useFetchUploadMediaUrl` and pass it any options that fit your needs.
 * When your component renders, `useFetchUploadMediaUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUploadMediaUrl({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useFetchUploadMediaUrl(baseOptions: Apollo.QueryHookOptions<FetchUploadMediaUrl, FetchUploadMediaUrlVariables> & ({ variables: FetchUploadMediaUrlVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUploadMediaUrl, FetchUploadMediaUrlVariables>(FetchUploadMediaUrlDocument, options);
      }
export function useFetchUploadMediaUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUploadMediaUrl, FetchUploadMediaUrlVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUploadMediaUrl, FetchUploadMediaUrlVariables>(FetchUploadMediaUrlDocument, options);
        }
export function useFetchUploadMediaUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchUploadMediaUrl, FetchUploadMediaUrlVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchUploadMediaUrl, FetchUploadMediaUrlVariables>(FetchUploadMediaUrlDocument, options);
        }
export type FetchUploadMediaUrlHookResult = ReturnType<typeof useFetchUploadMediaUrl>;
export type FetchUploadMediaUrlLazyQueryHookResult = ReturnType<typeof useFetchUploadMediaUrlLazyQuery>;
export type FetchUploadMediaUrlSuspenseQueryHookResult = ReturnType<typeof useFetchUploadMediaUrlSuspenseQuery>;
export type FetchUploadMediaUrlQueryResult = Apollo.QueryResult<FetchUploadMediaUrl, FetchUploadMediaUrlVariables>;