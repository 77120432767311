import { NewCharacterDialog } from '@/components/navbar/NewCharacterDialog';
import { UserDropdown } from '@/components/navbar/UserDropdown';
import type { Char } from '@/components/store/ConversationStore';
import { HStack } from '@/components/utilities/HStack';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { cn } from '@/lib/utils';
import { IconBallBasketball } from '@tabler/icons-react';
import Link from 'next/link';
interface NavbarViewProps {
  character?: Char | null;
}
export const NavbarView = (props: NavbarViewProps) => {
  const {
    character
  } = props;
  const {
    isEditing
  } = useRouterQuery();
  return <HStack className={cn('w-full py-[6px] px-[12px] justify-between items-center border-[1px]', isEditing ? 'border-black bg-black/30' : 'border-transparent bg-transparent')} data-sentry-element="HStack" data-sentry-component="NavbarView" data-sentry-source-file="NavbarView.tsx">
      <HStack className="w-[200px] space-x-[8px] text-[#f9f9f9] text-[10px] font-medium font-mono tracking-wide justify-start" data-sentry-element="HStack" data-sentry-source-file="NavbarView.tsx">
        <Link href="/" data-sentry-element="Link" data-sentry-source-file="NavbarView.tsx">
          <HStack className="items-center justify-center w-fit gap-[4px] opacity-50 hover:opacity-100 cursor-pointer p-[2px]" data-sentry-element="HStack" data-sentry-source-file="NavbarView.tsx">
            <IconBallBasketball className="w-4 h-4 text-[#FF7E33]" data-sentry-element="IconBallBasketball" data-sentry-source-file="NavbarView.tsx" />
            <div>fadeaway</div>
          </HStack>
        </Link>
      </HStack>
      {/* <NavbarToggle /> */}
      <HStack className="space-x-[8px] w-[200px] justify-end" data-sentry-element="HStack" data-sentry-source-file="NavbarView.tsx">
        <UserDropdown data-sentry-element="UserDropdown" data-sentry-source-file="NavbarView.tsx" />
      </HStack>
      <NewCharacterDialog data-sentry-element="NewCharacterDialog" data-sentry-source-file="NavbarView.tsx" />
    </HStack>;
};