import { UserContext } from '@/components/auth/UserContext';
import { __unsafe_useAuthStore } from '@/components/store/AuthStore';
import { useConversationStore } from '@/components/store/ConversationStore';
import { useFetchMe, type MeFragment } from '@/graphql/generated/query.types';
import { getAppSyncKey, useAppSync, type AppSyncEvent } from '@/hooks/useAppSync';
import { useEffectUnsafe } from '@/hooks/useEffectUnsafe';
import StartPage from '@/pages/start';
import VerifyPage from '@/pages/verify';
import WaitlistPage from '@/pages/waitlist';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState, type PropsWithChildren } from 'react';
import { ulid } from 'ulidx';

/**
 * Page component keeps track of the current user, and provides it to downstream components via the
 * UserContext. If there is no user, renders the Login page.
 */
export function AuthWrapper({
  children
}: PropsWithChildren<unknown>) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const {
    accessToken,
    login
  } = __unsafe_useAuthStore();
  const fetchRef = useRef(false);
  useEffect(() => {
    if (fetchRef.current) {
      return;
    }
    fetchRef.current = true; // prevent multiple fetches

    fetch('/api/refresh_token', {
      method: 'POST',
      credentials: 'include'
    }).then(async x => {
      const {
        accessToken
      } = await x.json();
      login(accessToken);
      // __unsafe_useAuthStore.getState()
      // setAccessToken(accessToken)
      setLoading(false);
    }).catch(e => console.error(e));
  }, [router.pathname]);
  if (loading) {
    return null;
  }
  if (!loading && !accessToken) {
    if (router.pathname.startsWith('/verify')) {
      return <VerifyPage />;
    } else if (router.pathname.startsWith('/start')) {
      return <StartPage />;
    } else if (router.pathname.startsWith('/waitlist')) {
      return <WaitlistPage />;
    }
    router.push('/start');
    return null;
  }
  if (router.route === '/_error') {
    return <>{children}</>;
  }
  return <UserContextWrapper data-sentry-element="UserContextWrapper" data-sentry-component="AuthWrapper" data-sentry-source-file="AuthWrapper.tsx">{children}</UserContextWrapper>;
}
function UserContextWrapper({
  children
}: PropsWithChildren<unknown>) {
  const {
    data,
    previousData,
    refetch,
    error
  } = useFetchMe({
    // pollInterval: 1000,
  }); // fetch to load apollo cache, use the auth token user to avoid waiting for render
  const me: MeFragment | null | undefined = data?.me ?? previousData?.me;
  const storedMe = useConversationStore(s => s.me) || {
    id: ulid(),
    name: 'anon' // backcompat
  };
  const handleAppSyncMessage = useCallback((message: AppSyncEvent) => {
    if (message.type === 'user_changed') {
      console.log('refetching user...');
      refetch();
    }
  }, []);
  useAppSync({
    key: getAppSyncKey({
      userId: me?.id || ''
    }),
    onMessage: handleAppSyncMessage,
    skip: !me?.id
  });
  useEffectUnsafe(() => {
    if (!me) {
      return;
    }
    useConversationStore.getState().initialize('onboarder', me.id);
    // useConversationStore.getState().clearAllMessagesAfter('01JEWYV3Q2VV0A029HP21NVKP4')

    const baseUrl = process.env.NEXT_PUBLIC_INFERENCE_URL || (process.env.NODE_ENV === 'production' ? 'https://inference.fadeaway.com' : 'http://localhost:7777');
    const {
      accessToken
    } = __unsafe_useAuthStore.getState();

    // fetch(`${baseUrl}/v1/generate-keypair`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //     'Content-Type': 'application/json',
    //   },
    // })
    //   .then(x => x.json())
    //   .then(x => {
    //     useConversationStore.getState().setPublicKey(x.publicKey)
    //     setGotPublicKey(true)
    //   })
    //   .catch(e => console.error(e))
  }, [me]);

  // const dbRef = useRef(new ImageDatabase())

  return me ?
  // <ImageDatabaseContext.Provider value={dbRef.current}>
  <UserContext.Provider value={{
    ...me,
    name: storedMe.name
  }} data-sentry-element="unknown" data-sentry-component="UserContextWrapper" data-sentry-source-file="AuthWrapper.tsx">{children}</UserContext.Provider>
  // </ImageDatabaseContext.Provider>
  : null;
}