import { apolloClient } from '@/graphql/apollo'
import {
  type FetchPresignedPutAvatarUrl,
  type FetchPresignedPutAvatarUrlVariables,
  FetchPresignedPutAvatarUrlDocument,
} from '@/graphql/generated/query.types'

export async function uploadAvatar(
  file: any,
  cb: {
    setIsUploading?: (uploading: boolean) => void
    onUpload: (avatarId: string) => void
  }
) {
  const blob = generateBlob(file)
  const res = await apolloClient.query<FetchPresignedPutAvatarUrl, FetchPresignedPutAvatarUrlVariables>({
    query: FetchPresignedPutAvatarUrlDocument,
    variables: { contentType: file.type },
    fetchPolicy: 'no-cache',
  })
  const presignedPutUrl = res.data.presignedPutAvatarUrl
  await uploadBlobToPresignedPutUrl(presignedPutUrl.url, blob, cb.setIsUploading)
  cb.onUpload(presignedPutUrl.id)
}

export function generateBlob(file: any) {
  return new Blob([file], { type: file.type })
}

export async function uploadFileToPresignedPutUrl(
  url: string,
  file: File,
  setIsUploading?: (uploading: boolean) => void
) {
  const blob = generateBlob(file)
  await uploadBlobToPresignedPutUrl(url, blob, setIsUploading)
}

export async function uploadBlobToPresignedPutUrl(
  url: string,
  blob: Blob,
  setIsUploading?: (uploading: boolean) => void
) {
  if (setIsUploading) {
    setIsUploading(true)
  }
  await fetch(url, { method: 'PUT', body: blob })
  if (setIsUploading) {
    setIsUploading(false)
  }
  return url
}
