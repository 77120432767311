// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"d23c3a64bdb43ba4e324866564e9bb547bfa626a"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://489fecb5bc9475c07962c541d3862ad3@o4505592783962112.ingest.us.sentry.io/4505862910509056',
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.STAGING ? 'staging' : 'production',
  skipOpenTelemetrySetup: true,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
})
