import { LayoutView } from '@/components/layout/LayoutView';
import { NavbarView } from '@/components/navbar/NavbarView';
import { uploadFileToPresignedPutUrl } from '@/components/utilities/uploader';
import { VStack } from '@/components/utilities/VStack';
import { apolloClient } from '@/graphql/apollo';
import { CreateMediaDocument, MediaUploadStatus, useFetchAllMedia, useSetMediaUploadStatus, type CreateMedia, type CreateMediaVariables } from '@/graphql/generated/query.types';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
export default function IndexPage() {
  const {
    data,
    refetch
  } = useFetchAllMedia();
  const [setMediaUploadStatus] = useSetMediaUploadStatus();
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    // Handle your files here
    console.log(acceptedFiles);
    const {
      data
    } = await apolloClient.mutate<CreateMedia, CreateMediaVariables>({
      mutation: CreateMediaDocument,
      variables: {
        creators: acceptedFiles.map(file => ({
          name: file.name,
          contentType: file.type
        }))
      }
    });
    const createMedia = data?.createMedia!;
    refetch();
    await Promise.allSettled(createMedia.map(async (media, i) => {
      const file = acceptedFiles[i];
      const putUrl = media.put.url;
      const getUrl = media.get.url;
      await uploadFileToPresignedPutUrl(putUrl, file);
      await setMediaUploadStatus({
        variables: {
          id: media.media.id,
          status: MediaUploadStatus.uploaded
        }
      });
    }));
    console.log('all files uploaded');
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept: {
      'audio/*': [],
      'video/*': []
    },
    noClick: true
  });
  return <LayoutView data-sentry-element="LayoutView" data-sentry-component="IndexPage" data-sentry-source-file="index.tsx">
      <VStack className="w-full h-full overflow-hidden" data-sentry-element="VStack" data-sentry-source-file="index.tsx">
        <NavbarView data-sentry-element="NavbarView" data-sentry-source-file="index.tsx" />
        <div {...getRootProps()} className={`flex-1 w-full overflow-auto transition-all ${isDragActive ? 'bg-blue-20/10' : ''}`}>
          <input {...getInputProps()} />

          {/* Show drag overlay when active */}
          {isDragActive && <div className="absolute inset-0 flex items-center justify-center bg-blue-50/20 pointer-events-none">
              {/* <p className="text-gray-500 text-xl">Drop your audio/video files here...</p> */}
            </div>}

          {/* Media List */}
          <VStack className="w-full p-4 gap-4" data-sentry-element="VStack" data-sentry-source-file="index.tsx">
            {data?.allMedia.map(media => <div key={media.id} className="p-4 border rounded-lg">
                {media.name}
              </div>)}
          </VStack>
        </div>
      </VStack>
    </LayoutView>;
}