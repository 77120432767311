import { AuthLayout } from '@/components/layout/AuthLayout';
import { __unsafe_useAuthStore } from '@/components/store/AuthStore';
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '@/components/ui/input-otp';
import { HStack } from '@/components/utilities/HStack';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
export default function VerifyPage() {
  const router = useRouter();
  const verificationId = router.query.verification as string | undefined;
  const {
    accessToken
  } = __unsafe_useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const {
    login
  } = __unsafe_useAuthStore();
  const inputRef = useRef<HTMLInputElement>(null);
  async function send() {
    setIsLoading(true);
    try {
      const response = await fetch('/api/verify', {
        body: JSON.stringify({
          code: code.trim().replace(/[^a-zA-Z0-9]/g, '').toUpperCase(),
          verificationId,
          type: 'operator'
        }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });
      const resp = response as Response;
      if (resp.status >= 500) {
        throw new Error('Network response was not ok');
      }
      const json = (await resp.json()) as {
        waitlist?: string;
        accessToken?: string;
        refreshToken?: string;
        errors?: any[];
      };
      if (json.errors) {
        setError(json.errors[0].message);
      } else if (json.accessToken) {
        login(json.accessToken);
        router.push('/');
      } else if (json.waitlist) {
        router.push('/waitlist');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred. Please try again!');
    } finally {
      setIsLoading(false);
      // input-otp automatically unfocuses the input after completion,
      // so we need to refocus it.
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }
  useEffect(() => {
    if (error.length > 0) {
      setError('');
    }
  }, [code]);
  useEffect(() => {
    if (accessToken) {
      router.replace({
        pathname: '/'
      }).catch(e => console.error(e));
    } else if (!verificationId) {
      router.push('/start').catch(e => console.error(e));
    }
  }, [accessToken, verificationId]);
  return <AuthLayout pageTitle="Verify" heroText="Check your email" secondaryText="Enter the 6 digit code we sent to your email." errorText={error} helperText="Can't find the email? Check your spam folder!" data-sentry-element="AuthLayout" data-sentry-component="VerifyPage" data-sentry-source-file="verify.tsx">
      <HStack className="space-x-[4px] justify-center w-full" data-sentry-element="HStack" data-sentry-source-file="verify.tsx">
        <InputOTP ref={inputRef} onComplete={send} value={code} pattern={REGEXP_ONLY_DIGITS_AND_CHARS} onChange={(value: string) => {
        setCode(value.toUpperCase());
      }} onKeyDown={e => {
        if (e.key === 'Enter' && code.length === 6) {
          send();
        }
      }} onPaste={e => {
        // Make pasting 123-456 to work.
        // Based on https://github.com/guilhermerodz/input-otp/blob/f6ef25c746bb8ef126c4b50b81dca744bb3c1678/packages/input-otp/src/input.tsx#L206-L231
        const input = e.target as HTMLInputElement;
        const content = e.clipboardData.getData('text/plain');
        const start = input.selectionStart ?? 0;
        const end = input.selectionEnd ?? 0;
        const isReplacing = start !== end;
        const newValueUncapped = isReplacing ? code.slice(0, start) + content + code.slice(end) // Replacing
        : code.slice(0, start) + content + code.slice(start); // Inserting
        const newValue = newValueUncapped.trim().replace(/[^a-zA-Z0-9]/g, '').toUpperCase().slice(0, 6);
        setCode(newValue);
        const _start = Math.min(newValue.length, 6 - 1);
        const _end = newValue.length;
        input.setSelectionRange(_start, _end);
      }} maxLength={6} containerClassName="group flex items-center has-[:disabled]:opacity-30 justify-center" disabled={isLoading} autoFocus data-sentry-element="InputOTP" data-sentry-source-file="verify.tsx">
          <InputOTPGroup data-sentry-element="InputOTPGroup" data-sentry-source-file="verify.tsx">
            <InputOTPSlot index={0} data-sentry-element="InputOTPSlot" data-sentry-source-file="verify.tsx" />
            <InputOTPSlot index={1} data-sentry-element="InputOTPSlot" data-sentry-source-file="verify.tsx" />
            <InputOTPSlot index={2} data-sentry-element="InputOTPSlot" data-sentry-source-file="verify.tsx" />
          </InputOTPGroup>
          <InputOTPSeparator data-sentry-element="InputOTPSeparator" data-sentry-source-file="verify.tsx" />
          <InputOTPGroup data-sentry-element="InputOTPGroup" data-sentry-source-file="verify.tsx">
            <InputOTPSlot index={3} data-sentry-element="InputOTPSlot" data-sentry-source-file="verify.tsx" />
            <InputOTPSlot index={4} data-sentry-element="InputOTPSlot" data-sentry-source-file="verify.tsx" />
            <InputOTPSlot index={5} data-sentry-element="InputOTPSlot" data-sentry-source-file="verify.tsx" />
          </InputOTPGroup>
        </InputOTP>
      </HStack>
    </AuthLayout>;
}